.title {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 45px;
    line-height: 54px;
    color: #03688D;
    text-align: center;
}

.text{
    line-height: 1.5em;
}

.heading_style {
    margin-top: 1em;
    font-size: 120%;
}

.section_style {
    margin: 2em;
    display: inline-block;
    text-align: justify;
    width: 40%;
}

.section_style_video {
    vertical-align: top;
}

.margin1em {
    margin: 1em;
}

.contentcenter {
    text-align: center;
}