.title {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 45px;
    line-height: 54px;
    color: #03688D;
    text-align: center;
}

.agencyTitleFill {
    background-color: #507285;
    padding: 5px 15px 5px 15px;
}

.agencyTitle {
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    color: #ffffff;
    letter-spacing: 1px;
}


.agencySubtitle {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #d7e3e7;
}

.spacer15U {
    padding: 15px 0px 0px 0px;
}

.spacer30U {
    padding: 30px 0px 0px 0px;
}

.spacer60U {
    padding: 60px 0px 0px 0px;
}

.section_001{
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 36px;
    text-align: justify;
    color: #595b5d;
    margin: 1em;
}

.section_002 {
    background-color: #e6f3f8;
}

.margin1em {
    margin: 1em;
}

.sectionUnderLine {
    height: 2px;
    border-style: none;
    border-color: transparent;
    background-color: #3B9ABD;
    position: relative;
}

.custom_page {
    margin: 2em auto;
    max-width: 75em;
}

.parameterGroup {
    margin: 1em;
    font-family: 'Lato', sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 43px;
    color: #054455;

}

.block {
    display: block;
}

.parameterList {
    margin-left: 2em;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 31px;
    color: #03688d;
}

.grayText{
    color: #b4b4b6
}

.learnMore {
    display: block;
    font-weight: 900;
    margin-bottom: 0.5em;
}

.noteThat {
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 13px;
    color: #054455;
    font-style: italic;
    display: block;
}
