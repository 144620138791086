@media screen and (max-device-width: 840px) {
    .liststyle {
        width: 100vw !important;
        margin: auto !important;
        height: auto !important;
        overflow-y: scroll !important;
        float: none !important;
    }

    .parametersCardStyle,
    .parametersCardStyleDetail,
    .cardMargin {
        width: 100% !important;
        margin-left: 0.5em !important;
    }
}

.liststyle {
    width: 24em;
    float: left;
    height: calc(100vh - 10em);
    overflow-y: auto;
}

.paperstyle {
    max-height: 30em;
    width: 22.2em;
    margin: 1em 0.5em 0.5em 0.2em;
    padding: 1em 1em;
    text-align: left;
    display: inline-block;
    overflow-y: auto;
    overflow-x: auto;
}

.buttonstyle {
    width: 20em;
    padding: 1em;
    display: inline-block;
    margin-left: 2em;
    margin-bottom: 2em;
}

.select {
    margin-top: 2em;
    max-width: calc(100%) !important;
}

.detailListStyle {
    width: 25em;
    margin-top: 2.5em !important;
    height: calc(100vh - 12em);
    overflow-y: auto;
}

.detailChart {
    margin-top: 4em;
    margin-left: 2em;
}

.detailTitle {
    padding: 0.2em 0.2em 0.2em 1em !important;
    width: 100%;
    position: absolute;
    background-color: #ffffff;
    z-index: 1;
}

.list_style {
    padding-left: 2em;
    list-style: none;
}

.textField {
    width: 8em;
    margin-bottom: -1em;
}

.counterText {
    float: left;
    margin-top: 1em;
    margin-left: 1em;
    text-align: left;
}

.submitButtonStyle {
    margin-top: 1em;
    margin-left: 1em;
    float: left;
    width: 45%;
}

.parametersCardStyle {
    width: 21em;
    margin: 0em 0.1em 1em 0.1em;
}

.parametersCardStyleDetail {
    width: 21em;
    margin: 0em 0.1em 1em 0.1em;
}

.cardMargin {
    margin: 0em 0.1em 1em 0.1em;
    width: 21em;
}

.selectedParameter {
    background-color: #3f51b5;
    padding: 0.5em;
    border-radius: 5em;
    font-weight: bold;
    color: white;
}

.capitalize_word {
    text-transform: capitalize;
}

.about_button {
    position: absolute;
    top: 9em;
    right: 0.8em;
}

.locations_button_style {
    float: right;
    cursor: pointer;
}

.close_button_style {
    color: red;
    cursor: pointer;
}