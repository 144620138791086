html {
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h5, h6, p, ul {
    margin: 0;
    padding: 0;
}

.content {
    padding: 10px;
}

.contentcenter {
    margin-top: 50px;
    padding: 10px;
    text-align: center;
}

.bodymap {
    display: flex;
    overflow: hidden;
}

@media screen and (max-device-width: 840px) {
    .rightMap {
        margin-left: -14.5em !important;
        float: none !important;
    }

    .leftColumn {
        width: 100vw !important;
        margin: auto !important;
        height: auto !important;
        overflow-y: scroll !important;
        float: none !important;
    }

    .card {
        width: 100% !important;
        margin-left: 0.5em !important;
    }

    h1 {
        font-size: xx-large !important;
        line-height: 120% !important;
        font-weight: bold !important;
    }

    section {
        font-size: xx-large !important;
        line-height: 120% !important;
    }

    h2, h3, h4, select, text, p {
        font-size: xx-large !important;
    }

    label {
        font-size: xx-large !important;
        line-height: 100% !important;
    }

    button {
        font-size: xx-large !important;
        margin: 0.2em !important;
    }

    .exploreButtonMobile {
        font-size: xx-large !important;
        margin: 0.2em 0.2em 0.2em 0em !important;
        width: 100% !important;
        padding-top: 0.25em !important;
        padding-bottom: 1.5em !important;
        background-color: white !important;
        color: black !important;
    }

    .viewSelectionStyle {
        padding: 1em;
    }

    .viewSelectionLabel {
        float: left;
        padding: 0.35em;
    }

    .viewSelectionRadios {
        display: inline-flex;
    }

    .chromeAlert {
        font-size: x-large;
        font-weight: bold;
        color: red;
    }
}

.rightMap {
    margin: 0em 0em 0em 0em;
    float: right;
}

/*TODO: change this. */
.leftColumn {
    width: 24em;
    margin: 0em 0em 3em 1em;
    float: left;
    height: calc(100vh - 9em);
    overflow-y: auto;
}

.leftActions {
    margin-left: auto;
    padding: 0.5em 0em 0.5em 1em;
    width: 24em;
    bottom: 0;
    background-color: #fff;
    text-align: center;
}

.card {
    width: 18em;
}

.noPadding {
    padding: 0 !important;
}

.error_text {
    color: #FF0000;
    padding: 1em;
    font-weight: bold;
}

.loading_text {
    margin: 25vh auto;
    padding: 3.5em 0 0 1em;
    left: 42.55%;
    position: absolute;
    width: 10em;
    height: 10em;
    color: #29ABE2;
    border: 1em inset #29ABE2;
    border-radius: 50%;
}

.loading_text_background {
    background-color: rgba(0, 0, 0, 0.6);
}

.loading_spinner {
    border-radius: 50%;
    width: 10em;
    height: 10em;
    border: 1em inset #29ABE2;
    -webkit-animation: spinner 4s linear infinite;
    -moz-animation: spinner 4s linear infinite;
    animation: spinner 4s linear infinite;
    margin: 25vh auto;
    z-index: 1000;
    position: absolute;
    left: 42.55%;
}

.make_modal {
    background-color: rgba(0, 0, 0, 0.6);
    height: calc(100vh - 8em);
    width: 100%;
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    margin: 8.2em 0 0 0;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.alertHeader {
    padding-bottom: 1em !important;
}

.alertHeaderIcon {
    font-size: 200%;
}

.alertHeaderText {
    font-weight: bold;
    font-size: larger;
}

.alertBodyText {
    color: #000000;
    font-size: larger;
}

.alertButton {
    font-weight: bold !important;
}

.title_card {
    font-size: larger !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
}

.close_button_style {
    color: red;
    cursor: pointer;
}

.detail_title {
    color: white;
    display: inline-block;
    padding-left: 2.5em;
    text-indent: -1em;
}

.detail_header {
    padding: 0.2em;
}

.clusterChoiceText {
    font-weight: bold;
    color: white;
}

.clusterChoiceTextActive {
    font-weight: bold;
    filter: invert(100%);
}

.clusterChoicePositioning {
    position: fixed;
    z-index: 1;
    width: 15em;
    bottom: 0;
    left: 57%;
    margin: 0 auto 0.9em auto;
    background-color: rgba(0, 60, 136, 0.6) !important;
    border-radius: 0.2em;
    border: solid rgba(255, 255, 255, 0.7);
}

.clusterStyleActive {
    filter: invert(100%);
}

.fullWidth {
    width: 100%;
}

.open_button_style {
    cursor: pointer;
    margin-left: -1em;
    position: relative;
}

.close_detail {
    cursor: pointer;
    float: right;
    color: white;
    margin-right: 20px;
}

.close_detail i {
    color: white;
}
